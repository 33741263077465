.modal-overlay {
    position: fixed;
    top: 10vh;
    left: 0vw;
    right: 0;
    bottom: 0;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    color: black;
    height: 50%;
  }
  
  .modal {
    background: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    width: 100%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1001;
    position: relative;
  }
  
  .close-button {
    position: absolute;
    color:black;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5em;
    cursor: pointer;
  }
  
  .modal-content {
    margin-top: 20px;
  }
  .modal-text{
    color: black;
  }
  
  @media (max-width: 768px) {
    .modal {
      width: 90%;
    }
  }
  
    
  .close-button{
    color:white;
  }
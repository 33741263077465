.artist-card {
  background: transparent;
  border-radius: 10px;
  width: 10vw;
  margin: 20px;
  text-align: center;
  height: 50vh;
  max-height: 70vh;
  transition: transform 0.3s;
  position: relative; 
}

.artist-card:hover {
  transform: scale(1.05);
}

.artist-name-cont {

 display: flex;
 justify-content: center;
}

.artist-name {
  margin: 0; 
  display: flex;
  font-weight: 200;
  color: white;
  position: relative;

  transform: scale(1);
padding: 10px;
  text-align: start;


}

.artist-image img {
  width: 80%;

  border-radius: 10px;
}

.bio-button {
  background-color: gold;
  position: absolute;
  color: black;
  font-weight: 300;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1em;
  margin-top: 20px;
  padding: 1em;
  transition: background-color 0.3s;
  bottom:0;
  width:100%;
  left:0;
 

}

.bio-button:hover {
  background-color: goldenrod;
}

.artists-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  
  padding: 20px;
  margin-top: 60px;
  background-color: #282c34;
  min-height: calc(100vh - 60px);
}

@media (max-width: 768px) {
  .artist-card {
      width: 20vw;
  }
  .artist-name {


text-wrap:wrap;

  }
  .artist-name-cont {
    display: flex;
  
    top:-3vh;
    height: 10vh;

    text-wrap:wrap;
   
      }
}

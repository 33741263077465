 /* General Styles */
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Arial', sans-serif;
}

.bio-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #1a1a1a;
  color: #f5f5f5;
  padding: 20px;
  position: relative;
  overflow: hidden;
}

.background-container {
  position: relative;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../images/mo3_1.png'); /* Replace with your background image path */
  background-size: cover;
  background-position: center;
  filter: blur(10px); /* Adjust the blur radius as needed */
  z-index: 1;
}

.content {
  position: relative;
  z-index: 2;
  text-align: center;
  padding: 20px;
  width: 100%;
  max-width: 800px;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.back-link {
  color: gold;
  text-decoration: none;
  font-size: 1.2rem;
  margin-bottom: 20px;
  transition: color 0.3s ease;
}

.back-link:hover {
  color: goldenrod;
}

.artist-title {
  font-size: 2.5rem;
  margin-top: 10px;
}

.photo-section {
  margin: 20px 0;
  display: flex;
  justify-content: center;
}

.artist-photo {
  width: 100%;
  max-width: 300px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.bio-section {
  text-align: left;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  position: relative;
  left: 0%;
}
@media(max-width:768px){
  .bio-section{
    left: 0%;
  }
}
.bio-section p {
  font-size: 1.2rem;
  line-height: 1.6;
}

.audio-section {
  margin-top: 20px;
  position: relative;
  

}

.audio-section audio {
  width: 100%;
}

/* Fade-in Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 0.5s ease-in-out;
}

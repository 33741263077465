/* General Styles */
body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: 'Arial', sans-serif;
}

.contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #282c34;
    color: white;
    padding: 20px;
}

.contact-form {
    background-color: rgba(6, 5, 5, 0.8);
    display: flex;
    border: 1px solid transparent;
    border-radius: 10px;
    padding: 2%;
    flex-direction: column;
    max-width: 600px;
    width: 100%;
    box-shadow: 0 4px 8px gold;
    animation: fadeIn 0.5s ease-in-out;
}

.contact-header {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
    color: gold;
}

.contact-label {
    font-size: 1.2rem;
    margin-bottom: 5px;
    color: white;
}

.contact-input, .contact-textarea, .contact-submit {
    padding: 10px;
    margin-bottom: 15px;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
}

.contact-input, .contact-textarea {
    background-color: #fff;
    color: #333;
}

.contact-submit {
    background-color: gold;
    color: #282c34;
    cursor: pointer;
    font-weight: 600;
    transition: background-color 0.3s ease;
}

.contact-submit:hover {
    background-color: goldenrod;
}

.contact-textarea {
    height: 150px;
    resize: vertical;
}

.back-link {
    color: gold;
    text-decoration: none;
    font-size: 1.2rem;
    margin-bottom: 20px;
    transition: color 0.3s ease;
}

.back-link:hover {
    color: goldenrod;
}

/* Keyframes for animations */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.artist-header{
    display: flex;
    justify-content: center;
color:gold;
font-weight: 800;
background-color:rgba(0,0,0,.5);
width: 114%;
position: relative;
bottom:47%;
right: 7%;

}

.artist-box{
    border: 10px solid gold;
}

.more-info{
    color:black;
    display:flex;
    position: relative; top:13vh;
    align-self: center;
    border: 1px solid transparent;
    border-radius: .5em;
    width: 10vw;
    font-weight: 600;
    justify-content: center;
    background-color:gold
}
.artist-title{
    color:white;
    font-weight: 600;
    font-size: 3em;
}
.dynasty{
    max-width:auto;
    display: flex;
    justify-content: center;
    width:40vw;
    border: none;
    background-color:none;
}
@media (max-width: 768px){
    .dynasty{
        width:100vw;
        position: relative;
        left:0%;
    };
  
}
.dyna-cont{
    width: 100vw;



}



@media (max-width:768px) {
    .dyna-image{
        margin-left: 0%;
    }
}

    

.learn-more{
    color:black;
    background-color: gold;
    border: 1px solid transparent;
    font-weight: 600;
    width:13vw;
    height:5vh;
    border-radius: .3em;
    position: relative; top:1vh;
}
.learn-more-cont{
display: flex;
justify-content: center;
height:auto;
position: relative;

top:5%;
left:24%;
}

@media (max-width:768px) {
    .learn-more-cont{
        margin-left: 0%;
        left:0%;
    }
    .learn-more{
        width:20vw;
    }
}


.artists-title{
    margin:0;
}

.artist-flex{
    display: flex;
    align-items: start;

    background-color: red;
    width:75vw;
    height: 50vh;

    
    
    
    
    
    }
    @media (max-width:768px) {
        .artist-flex{
            width:50vw;
        }
    }
    
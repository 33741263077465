/* General Styles */
body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: 'Georgia', serif;
}

/* Container */
.bio-page2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #1a1a1a;
    color: #f5f5f5;
    padding: 20px;
    position: relative;
    overflow: hidden;
}

.background-container {
    position: relative;
}

.background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../images/dsqlogo.jpeg'); 
    background-size: cover;
    background-position: center;
    filter: blur(10px); 
    z-index: 1;
}

.content {
    position: relative;
    z-index: 2;
    color: white;
    text-align: center;
    padding: 26px;
}

/* Header */
.back-link {
    color: gold;
    background-color: rgba(0,0,0,.6);
    text-decoration: none;
    font-size: 2.2em;
    margin-bottom: 20px;
}

/* .artist-name {
    font-size: 2.5em;
    margin-top: 10px;
    position: relative;
    display: flex;
    justify-content: center;
 
} */

/* Photo Section */
.photo-section {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
}

.photo-section img {
    width: 100%;
    max-width: 300px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

/* Bio Section */
.bio-section2 {
    display: flex;
    flex: 1;
    text-align: center;
    margin-bottom: 20px;
    padding: 25px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    width: 120%;
    position: relative;
    justify-self: center;


}

.bio-section2 p {
    font-size: 1.2em;
    line-height: 1.6;
}

/* Audio Section */
.audio-section2 {
    width: 100%;
    max-width: 600px;
    margin-top: 20px;
    position: relative;
    display: flex;
   justify-self: center;
   justify-content: center;
}

.audio-section2 audio {
    width: 100%;
    justify-content: center;
    position: relative;
    left:3%;
   
}

/* Responsive Design */
@media (min-width: 768px) {
    .bio-page2 {
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
    }

    .photo-section {
        margin-right: 20px;
    }

    .bio-section2 {
        max-width: 600px;
        margin: 0 20px;
    }

    .audio-section2 {
        margin-left: 20px;
        left:4%;
      
    }
}
.artist-bio2{
    position: relative;
    left:11%;

}

@media (max-width: 768px) {
    .photo-section img {
        width: 80%;
   
    }
    .artist-bio2{  
        position: relative;
        left:-8vw;


}

    
  
}
.fade-in {
    animation: fadeIn 0.5s ease-in-out;
}
 
/* General Styles */
body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: 'Arial', sans-serif;
  }
  
  /* Page Container */
  .dynasty-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #282c34;
    color: white;
    position: relative;
    overflow: hidden;
    padding: 20px;
  }
  
  .background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../images/dsqlogo.jpeg'); 
    background-size: cover;
    background-position: center;
    filter: blur(10px); 
    z-index: 1;
  }
  
  .content {
    position: relative;
    z-index: 2;
    text-align: center;
    padding: 20px;
    width: 100%;
    max-width: 800px;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }
  
  .back-link {
    color: gold;
    text-decoration: none;


    margin-bottom: 20px;
    transition: color 0.3s ease;
  }
  
  .back-link:hover {
    color: goldenrod;
  }
  
  .dynasty-section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .photo-section {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
  }
  
  
  .dynasty-photo {
    width: 100%;

    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    

   
  }
  
  .bio-section-dyna {
    text-align: center;
    padding: 20px;
    
  }
  
  .dynasty-text {
    font-size: 1.2rem;
    line-height: 1.6;
  }
  
  .dynasty-image{
    position: relative;

    display: flex;

  }

  /* Fade-in Animation */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .fade-in {
    animation: fadeIn 0.5s ease-in-out;
  }
  
  .dyna-cont{
display: flex;
justify-content: center;

  }
  .dynasty-image{
    position: relative;
    left:50%;
    display: flex;
  

  }
  @media(max-width:768px) {
    
    .dynasty-image{
      position: relative;
      left:0%;
      display: flex;
  
    }
  }
  .learn-more{
    position: relative;
    left:7%;
  }